html,
body {
    height: 100%;
}

/* .main-container {
    width: 100%;
    min-height:calc(82% - 44px);
} */
.main-container {
    font-family: 'Barlow Condensed', sans-serif;
    width: 100%;
    /* min-height: calc(100% - 50px); */
    padding-bottom: 0;
}

.feature ul {
    margin: 30px 0px 30px 16px;
}

.feature ul li {
    margin-bottom: 20px;
    list-style: disc;
}

.sign-in-button {
    margin-bottom: 20px;
}

.coming-soon {
    margin-top: 10px;
    margin-bottom: 10px;
}

.download-text {
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 10px;
}

.download-section {
    text-align: center;
}

.putt-box {
    box-shadow: 0px 1px 10px rgba(83, 88, 74, 0.3);
    -webkit-box-shadow: 0px 1px 10px rgba(83, 88, 74, 0.3);
    background: #fff;
     
}

.putt-box .value-add span {
    /* font-family: 'Barlow Condensed', sans-serif; */
    font-size: 33px;
    font-weight: 600;
}

.btn-primary {
    background: #fe8002;
    border: 0;
    /* box-shadow: 2px 14px 10px -10px rgba(242, 171, 57, 0.55);
    -webkit-box-shadow: 2px 14px 10px -10px rgba(242, 171, 57, 0.55); */
}

.btn-primary:hover,
button.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary:active:hover {
    background: #fe8002;
}

.btn-circle {
    background: #c72729;
    border-radius: 50%;
    /* box-shadow:2px 14px 10px -10px rgba(199, 39, 41, 0.41);
    -webkit-box-shadow:2px 14px 10px -10px rgba(199, 39, 41, 0.41); */
    /* height: 34px; */
    /* width: 34px; */
    font-weight: bold;
    text-align: center;
    line-height: 26px;
    padding: 4px;
    color: #fff;
}

.putt-pin-green h3.puttlength-calc, .putt-pin-green h3.pinareaslope-calc, .putt-pin-green h3.greenspeed-calc {
    color: #00f;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
    font-size: 18px;
}
.putt-pin-green h3.pinareaslope-calc {
    color: #ff8101;
}
.putt-pin-green h3.greenspeed-calc {
    color: #007100;
}

.putt-pin-green h3 {
    font-size: 17px;
}

.putt-pin-green h3 span {
    vertical-align: text-top;
    top: 2px;
}

.area .value-add {
    background: #ff8101;
}

.green .value-add {
    background: #007100;
}

.footer {
    background: #fff;
}
.menu-btn sup {
    font-size: 11px;
    margin-top: 0;
    display: inline-block;
    position: relative;
    top: -7px;
    left: -1px;
}

/****** setting-section *******/
.setting .setting-text-heading {
    margin-bottom: 6px;
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
}

.setting {
    margin: 22px 0px 00px 22px;
}

.setting input[type="radio"] {
    margin-right: 7px;
}

.setting .setting-label-text {
    margin-right: 15px;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.focus,
.btn-primary:active:focus {
    color: #fff;
    background-color: #ca8f12;
    border-color: #b07b0b;
}

a {
    color: #676565;
}

.active,
.menu-btn:hover a {
    color: #77a124;
}

/****************** for-graph-slider **************************/

#ex1Slider .slider-selection {
    background: #fff;
}

.slider-handle {
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    /*background-color: #79a324;
    background-image: linear-gradient(to bottom, #79a324 0, #5d8213 100%);
    background-image: linear-gradient(to bottom, #79a324 0, #5d8213 100%);
    background-image: linear-gradient(to bottom, #79a324 0, #5d8213 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
    filter: none; */
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
    border: 0 solid transparent;
}


.g-head {
    font-size: 12px;
    min-width: 90px;
    display: inline-block;
}

.clock-watch {
    position: relative;
    width: 176px;
    margin: 0 auto;
    margin-top: 3px;
}

.clock-numbers ul .number {
    position: absolute;
    border: 2px solid #a94442;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    list-style: none;
    background: #fff;
    color: #000;
    font-weight: 600;
    width: 15%;
    height: 15%;
    padding: 0px 0;
    z-index: 10;
    font-family: 'Barlow Condensed', sans-serif;
    line-height: 25px;
}

.clock-numbers ul li{
    cursor: pointer;
}
.clock-numbers ul .ten {
    right: 82%;
    top: 22%;
}

.clock-numbers ul .eleven {
    right: 65%;
    top: 5%;
}

.clock-numbers ul .twelve {
    margin: 0 auto;
    left: 1px;
    right: 0;
    top: -2%;
}

.clock-numbers ul .nine {
    right: 87%;
    top: 43%;
}

.clock-numbers ul .eight {
    right: 81%;
    top: 66%;
}

.clock-numbers ul .seven {
    right: 65%;
    top: 81%;
}

.left-to-right-calc {
    font-weight: 500;
    font-size: 21px;
}

.select-value input.btn.btn-circle.btn-value {
    padding: 8px 0;
    font-size: 34px;
    font-weight: 600;
}

.clock-numbers ul .six {
    right: 42%;
    top: 87%;
}

.clock-numbers ul .five {
    right: 19%;
    top: 81%;
}

.clock-numbers ul .four {
    right: 4%;
    top: 66%;
}

.clock-numbers ul .three {
    right: -2%;
    top: 43%;
}

.clock-numbers ul .two {
    right: 3%;
    top: 21%;
}

.clock-numbers ul .one {
    right: 19%;
    top: 4%;
}

.clock-numbers ul .number:hover {
    background: #c72729;
    color: #fff;
}

.putt-pin-green h3 span {
    vertical-align: text-top;
    top: 2px;
    font-size: 14px;
}

button.btn.btn-circle.pull-left.dec-min {
    left: 294px;
    position: absolute;
    top: -49px;
    font-size: 28px;
    font-weight: 600;
}

button.btn.btn-circle.pull-right.inc-min {
    right: 275px;
    position: absolute;
    top: -49px;
    font-size: 28px;
    font-weight: 600;
}

/*****************  desktop-css  ************************/

/*.navbar-default {
    background-color: #7aa424;
    border-color: #7aa424;
}*/

.navbar {
    position: relative;
    min-height: 72px;
    margin-bottom: 0;
}
.navbar-brand {
    padding: 3px 15px;
}

.navbar-nav > li > a {
    padding-top: 13px;
    padding-bottom: 25px;
}

.head-banner {
    background: #8fcc15;
    padding: 10px 0;
    box-shadow: 0px 1px 10px rgba(148, 179, 85, 0.85);
    -webkit-box-shadow: 0px 1px 10px rgba(148, 179, 85, 0.85);
    margin-bottom: 0px;
    z-index: 999;
    margin-top: -21px;
    position: relative;
}

.head-banner h2{
    font-size: 22px;
    color: #fff;
    font-weight: normal;
}

.footer{
    display: none;
}  

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: #8fcc15;
    background-color: #f1f1f1;

}

.nav i{
    margin-right: 3px;
}

.nav .GR-Active .fa{
    width: 22px
}

.D-footer {
    background: #ececec;
    padding: 13px 0;
    text-align: center;
    font-size: 15px;
    color: #9a9a9a;
    position: absolute;
    width: 100%;
    bottom: 0;
    clear: both;
}

.D-profile{
    margin-right: 6px;
}

.D-profile a img {
    border: 2px solid #68940e;
    border-radius: 50%;
    width: 36px;
    margin-right: 5px;
    margin-top: -8px;
}

.D-profile a{
    padding: 15px 0px 0px 10px !important;
}

.D-profile a span{
    position: relative;
    top: -16px;
    font-weight: 500;
}

.plus-minus-angle .btn-circle:hover, .plus-minus-angle .btn-circle:focus{
    color: #fff;
    background: #c72729;
    outline: none;
}

.set-angles img{
  transition: transform 0.2s linear;
  transform-origin: 129px 16px;
  transform-style: preserve-3D;
}

.D-signup{
    background: #f2ab39;
    margin: 20px 12px 0 0;
    padding: 5px 12px;
    color: #fff;
    border: 1px solid #efa631;
    border-radius: 5px;
}

.D-header .navbar-nav > li > a{
    font-size: 18px !important;
}

.D-setting{
    background: #fff;
    text-align: left;
    width: 100%;
    margin: 0 auto;
    padding: 12px 0 38px 27px;
    box-shadow: 0px 1px 10px rgba(234, 233, 233, 0.85);
    -webkit-box-shadow: 0px 1px 10px rgba(234, 233, 233, 0.85);
}

.D-setting .S-head{
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 600;
    margin-top: 20px;
}

#root{
    height: 100%;
}

.set-angles .clock-numbers .clock-active{
    background: #c72729;
    color: #ffffff;
}

.bold-font{
    font-weight: bold;
}

.niddle{
    transform: rotate(15deg);
}

.small-size{
    font-size: 14px;
}

.D-profile a{
    color: #fff;

}
.D-profile .dropdown-menu {
    top: 70px;
}
.D-profile .dropdown-menu .dropdown-item{
    width: 100%;
    color: #000;
    padding: 15px;
}

.header-title {
    color: #fff;
    font-size: 27px;
    line-height: 28px;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Barlow Condensed', sans-serif;
}

.header h3 sup {
    font-size: 10px;
    top: -13px;
}

.see-putt{
    margin: 5px auto;
    text-align: center;
    display: none;
}
.plus-minus-angle button {
    border-radius: 100px;
    width: 44px;
    height: 44px;
    font-family: 'Barlow Condensed', sans-serif;
}
/****** get app-section *******/
.app-text-heading {
    margin-bottom: 6px;
    font-size: 17px;
    font-weight: 700;
    margin-top: 20px;
}
.green-reading h3 a.btn{
    margin-left: 10px;
    padding: 10px 12px;
    font-size: 16px;
    height: 40px;
        width: 150px;
}
.green-reading .read-putt h3{
    color: #000000;
}

span.controlSlider{
    background: #8fcc15;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    padding: 4px 0;
    width: 28px;
    height: 28px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
}
span.controlSlider i{
    position: relative;
    top: 2px;
}

.successMessage p{
    font-size: 16px;
    margin-top: 10px;
    color: '#8fcc15'
}

/* kuldeep 5thJun'19 */
.bg-light-green {
    background: #99db16;
    
}
.mt-2 {
    margin-top: .5rem;
}
.mb-2 {
    margin-bottom: .5rem;
}

/* kuldeep 7thJun'19 */
#D-menu .D-profile a.D-signup,
#D-menu .D-profile a:hover,
#D-menu .D-profile a:active {
    margin-top:10px;
    font-size: 16px !important;
    color: #fff;
    line-height: 16px;
    vertical-align: middle;
    padding: 5px 12px !important;
    background: #f2ab38;
}
.intro-video > h3 {
    font-weight: 600;
}

.animate{
    padding: 5px 5px !important;
    font-size: 21px !important;
}

.graphical-view-slider .g-slider li p .g-value{
    /* font-size: 14px !important; */
}


/* Written by vishal verma */


.animate-button-img{
    height: 16px;
    padding: 2px 1px 0 5px;
}

/*Deepak 27 june*/
.graphical-view-slider .g-slider.putt-time span.controlSlider,
.graphical-view-slider .g-slider.putt-time .slider-selection,
.graphical-view-slider .g-slider.putt-time .slider-handle{
    background: #c72729;
}
.graphical-view-slider .g-slider.putt-length span.controlSlider,
.graphical-view-slider .g-slider.putt-length .slider-selection,
.graphical-view-slider .g-slider.putt-length .slider-handle{
    /* background: #006ffb; */
    background: blue;
}
.graphical-view-slider .g-slider.pin-area span.controlSlider,
.graphical-view-slider .g-slider.pin-area .slider-selection,
.graphical-view-slider .g-slider.pin-area .slider-handle{
    background: #ff8101;
}
.graphical-view-slider .g-slider.green-speed span.controlSlider,
.graphical-view-slider .g-slider.green-speed .slider-selection,
.graphical-view-slider .g-slider.green-speed .slider-handle{
    background: #10bd4f;
}

.graphical-view-slider .g-slider.putt-time .slider-handle,
.graphical-view-slider .g-slider.putt-length .slider-handle,
.graphical-view-slider .g-slider.pin-area .slider-handle,
.graphical-view-slider .g-slider.green-speed .slider-handle{
    box-shadow: 0 0 2px #000;
}

.graphical-view-slider{
    padding-top: 20px;
}
.g-slider.putt-time span.g-head,
.g-slider.putt-time span.g-value{
    color: #c72729;
}
.g-slider.putt-length span.g-head,
.g-slider.putt-length span.g-value{
    /* color: #006ffb; */
    color: blue;
}
.g-slider.pin-area span.g-head,
.g-slider.pin-area span.g-value{
    color: #ff8101;
}
.g-slider.green-speed span.g-head,
.g-slider.green-speed span.g-value{
    color: #10bd4f;
}

.slider-handle.round{
    border-radius: 4px;
}
span.controlSlider{
    border-radius: 2px;
}
.graph-slides button{
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    border: 0;
    font-size: 28px;
    position: absolute;
    left: 50%;
    top: -10px;
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
}
.graph-slides button:focus{
    outline: none;
    /* transform: rotate(90deg) */
}

.corner-pinarea, 
.corner-greenspeed{
    position: fixed;
    top: 0;
}
.corner-pinarea img, 
.corner-greenspeed img{
    width: 90px;
    height: 90px;
}
.corner-greenspeed span{
    transform: rotate(-45deg);
}
.corner-pinarea span{
    transform: rotate(45deg);
}
.corner-pinarea span, 
.corner-greenspeed span{
    position: absolute;
    margin: 15px -5px 0px -5px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.05rem;
    text-align: center;
    width: 60px;
}
.corner-greenspeed, 
.corner-greenspeed span{
    left: 0;
    color: #10bd4f;
}
.corner-pinarea, 
.corner-pinarea span{
    right: 0;
    color: #ff8101;
}

.vSliderBox .vSlider{
    position: fixed;
}

.vPLength .slider-handle,
.vPLength .slider-track-high{
    /* background: #006ffb; */
    background: #fff;
    outline: 3px solid blue;
}
.vPuttTime .slider-handle,
.vPuttTime .slider-track-high{    
    background: #fff;
    outline: 3px solid #c72729;
}
.vPASlope .slider-handle,
.vPASlope .slider-track-high{
    background: #fff;
    outline: 3px solid #ff8101;
}
.vGreenSpeed .slider-handle,
.vGreenSpeed .slider-track-high{
    background: #fff;
    outline: 3px solid #007100;
}

.vSlider .slider-selection{
    /* border: 1px solid #ccc; */
}

.vSliderBox .vSlider.vGreenSpeed {
    bottom: 121px;
    left:26px;
}
.vSliderBox .vSlider.vPASlope {
    bottom: 121px;
    left: 102px;
}
.vSliderBox .vSlider.vPLength {
    bottom: 121px;
    right:44px;
}
.vSliderBox .vSlider.vPuttTime {
    bottom: 121px;
    right: 122px;
}
.vSlider .slider.slider-vertical{
    height: 153px;/* previous 160*/
}
.btn-red, .btn-red:focus{
    background: #e1140f;
    color: #fff;
}
#putt-whisperer{
    float: left;
    width: 100%;
    margin-top: -4px;
}
.slider.slider-vertical .slider-tick, 
.slider.slider-vertical .slider-handle{
    margin-top: -5px;
}
.mp-0{
    padding: 0;
}
.header-labels-flex sup {
    top: -10px;
    font-size: 15px;
}
.puttpower-value {
    padding-right: 20px;
}
.putt-break.puttpower-value sup {
    top: 8px;
}


/* written by vishal 29-7-2019 */

a:focus, a:hover{
    text-decoration: none;
}

.slider.slider-horizontal .slider-selection, 
.slider.slider-horizontal .slider-track-low, 
.slider.slider-horizontal .slider-track-high{
    border: 1px solid #ccc;
    border-right: 0;
    border-left: 0;
    background: #fff;
    border-radius: 0;
}

.slider.slider-horizontal .slider-track:before,
.slider.slider-horizontal .slider-track:after{
    width: 20px;
    height: 10px;
    background: #fff;
    border: 1px solid #ccc;
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: 10px;
    top: 0;
}
.slider.slider-horizontal .slider-track:before{
    left: -10px;
}
.slider.slider-horizontal .slider-track:after{
    right: -10px;
}

.g-slider.putt-time .slider.slider-horizontal .slider-track:before{
    background: #c72729;
}
.g-slider.putt-length .slider.slider-horizontal .slider-track:before{
    background: blue;
}
.g-slider.pin-area .slider.slider-horizontal .slider-track:before{
    background: #ff8101;
}
.g-slider.green-speed .slider.slider-horizontal .slider-track:before{
    background: #10bd4f;
}

.slider.slider-vertical .slider-track-low, .slider.slider-vertical .slider-track-high {
    width: 31px;
    border-radius: 0;
}
.slider-handle.min-slider-handle.round {
    width: 41px;
}
span.small-size {
    display: inline-block;
    margin-top: 5px;
    margin-left: 2px;
}
.vGreenSpeed .slider-handle.min-slider-handle.round {
    outline: none;
    background: #007100;
    z-index: 999;
}
.vPLength .slider-handle.min-slider-handle.round {
    outline: none;
    background: blue;
    z-index: 999;
}
.vPASlope .slider-handle.min-slider-handle.round {
    outline: none;
    background: #ff8101;
    z-index: 999;
}
.vPuttTime .slider-handle.min-slider-handle.round {
    outline: none;
    background: #c72729;
    z-index: 999;
}
.slider.slider-vertical .slider-track {
    width: 33px;
    top: -1px;
}
span.slider-text-container {
    position: absolute;
    transform: rotate(-90deg);
    font-size: 24px;
    font-weight: 600;
    z-index: 9;
}
.vPLength > .slider-text-container {
    bottom: 46px;
    left: -35px;
    color: blue;
    font-family: 'Barlow Condensed', sans-serif;
}
.vPuttTime > .slider-text-container {
    bottom: 38px;
    left: -27px;
    color: #c72729;
    font-family: 'Barlow Condensed', sans-serif;
}
.vPASlope > .slider-text-container {
    bottom: 56px;
    left: -44px;
    color: #ff8101;
    font-family: 'Barlow Condensed', sans-serif;
}
.vGreenSpeed > .slider-text-container {
    bottom: 44px;
    left: -34px;
    color: #007100;
    font-family: 'Barlow Condensed', sans-serif;
}


div.main-container > div > div > div.vSliderBox > div.vSlider {
    bottom:108px;
}
span.slider-value-container {
    position: absolute;
    bottom: -52px;
    font-weight: 500;
    color: #fff;
    font-size: 47px;
    font-family: 'Barlow Condensed', sans-serif;
    text-shadow: 1px 2px 6px #000;
}
.vPASlope span.slider-value-container {
    left: -20px;
    width: 100px;
    text-align: center;
}
.vGreenSpeed span.slider-value-container {
    left: -17px;
    width: 76px;
    text-align: center;
}
.vPuttTime span.slider-value-container {
    left: -32px;
    width: 96px;
    text-align: center;
}
.vPLength span.slider-value-container {
    left: -12px;
    width: 72px;
    text-align: center;
}

.see-putt {
    display: none;
}

span.slider-plus-btn {
    position: absolute;
    top: -112px;
    font-weight: 600;
    font-size: 19px;
    left: 2px;
    width: 38px;
    height: 38px;
    background: #c72729;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    padding-top: 7px;
    padding-left: 0px;
}
span.slider-minus-btn {
    position: absolute;
    top: -64px;
    font-weight: 600;
    font-size: 19px;
    left: 2px;
    width: 38px;
    height: 38px;
    background: #c72729;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    padding-top: 7px;
    padding-left: 0px;
}

.vPLength span.slider-minus-btn {
    background: blue;
}
.vGreenSpeed span.slider-minus-btn {
    background: #007100;
}
.vPASlope span.slider-minus-btn {
    background: #ff8101;
}
.vPLength span.slider-plus-btn {
    background: blue;
}
.vGreenSpeed span.slider-plus-btn {
    background: #007100;
}
.vPASlope span.slider-plus-btn {
    background: #ff8101;
}


.puttbreak-value {
    font-size: 50px;
    padding-left: 2px;
    padding-right: 7px;
    font-weight: 500;
    margin-top: -8px;
}
.puttpower-value {
    font-size: 50px;
    padding-left: 7px;
    padding-right: 16px;
    font-weight: 500;
    margin-top: -8px;
    position: relative;
}
.graph_header {
    height: 60px;
}

.header-puttbreak-text, .header-puttpower-text {
    font-size: 28px;
    letter-spacing: 1px;
    font-weight: 500;
}

button.btn-purple.animate sup {
    font-size: 7px;
    top: -8px;
}
.header-puttpower-text sup {
    top:-11px;
    font-size: 8px;
}

.puttpowersup {
    font-size: 20px;
    margin-left: 39px;
    margin-top: -25px;
}
span.puttbreak-header-text-container {
    margin-left: 60px;
    margin-top: 6px;
    float: left;
    font-size: 14px;
}

.puttpower-header-text-container {
    margin-right: 67px;
    margin-top: 6px;
    float: right;
    font-size: 14px;
}


.header-container{
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding-top: 3px;
    background: #007100;
    color: #fff;
    font-family: 'Barlow Condensed', sans-serif;
    padding: 0px 5px;
}

.header-labels-flex{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.header-labels{
    width: 100%;
}
.right_center {
    text-align: center;
    padding: 1px 0 9px 0px;
    /* font-size: 30px; */
    font-size: 20px;
    font-weight: 500;
    margin-top: -4px;
}

.putt-break.puttpower-value sup {
    font-size: 23px;
    font-weight: 600;
    position: absolute;
    top: 7px;
    right: 4px;
}
.putt-play-icon {
    margin-left: 6px;
}

.graphical-view-slider, .tooltip.tooltip-main.right {
display: none;
}
#desktop-header{display: block !important;}
/* #mobile-header{display: none !important;} */
.header-labels-flex {
    font-size: 18px;
}

.graph-container {
    /* width: 680px; */
    margin: 0 auto;
    position: relative;
    overflow-x: hidden;
}

.graph-container .container {
    width: 100%;
}
.vPuttTime .slider-handle.min-slider-handle.round {
    height: 16px;
}
.vPLength .slider-handle.min-slider-handle.round {
    height: 16px;
}
.vPASlope .slider-handle.min-slider-handle.round {
    height: 16px;
}
.vGreenSpeed .slider-handle.min-slider-handle.round {
    height: 16px;
}
.paslope_percentage {
    font-size: 28px;
    top: -10px;
    position: relative;
}

.percentage{
    font-size: 20px !important;
    /* margin-left: 25px; */
    margin-top: -10px;
}

#container > button.btn.btn-purple.btn-lg.animate > sup {
    top: 9px;
    position: absolute;
}
