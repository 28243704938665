html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
body{
	font-family: 'Roboto', sans-serif;
    min-height: 100%;
    height: auto;
}

/**************** media query for Extra large desktop *********************/
@media screen and (min-width: 1900px) {
   
}
/**************** media query for large desktop *********************/
@media screen and (min-width: 1600px) {
 .intro-video .video .pelz-V {
        height: 596px;
    }
    .D-greenReading {
        bottom: 0 !important;
    }
    .tutorial-video .video .pelz-V {
        height: 596px;
    }

    .main-container .intro-video {
        float: left;
        width: 47%;
        margin-left: 2%;
    }
    
    .main-container .tutorial-video {
        width: 47%;
        float: right;
        margin-right: 2%;
    }
    
    .green-reading {
        padding: 36px 0 !important;
    }
    
    .read-putt .btn{
        padding: 8px 32px;
        font-size: 18px;
    }
    
    .main-container #putt-whisperer {
        width: 1870px;
    }
    
    .set-angles .clock-watch {
        position: relative;
        width: 550px;
        background: url(../img/watch.png);
        height: 550px;
        background-size: cover;
        margin: 0 auto;
    }
    
    .clock-numbers ul .number{
         line-height: 46px;
    }
    
    .clock-watch .pin {
        position: absolute;
        left: 20%;
        top: 50%;
        transform: translateY(-38%);
        width: 194px;
    }
    
    .putt .break h3 {
        font-size: 28px;
        font-weight: 700;
        margin: 14px 0;
    }
    
    .putt .break p {
        font-size: 20px;
        font-weight: 500;
    }
    
    .putt  .break {
        padding: 74px 28px;
    }
    
    .putt .select-value .btn-circle{
        font-size: 28px !important;
        width: 84px;
        height: 53px;
        margin-top: 4px;
    }
    
    .putt .value-add {
        font-size: 44px;
        line-height: 135px;
    }
    
    .putt-pin-green .D-putt {
        margin-bottom: 38px;
    }
    
    .set-angles img {
        transition: transform 0.2s linear;
        transform-origin: 168px 19px;
        transform-style: preserve-3D;
    }
    
    .container {
        width: 1865px;
    }
    
    .D-graph {
    height: 730px !important;
    }
}

/**************** media query for desktop *********************/

@media (min-width:1281px) { 


    


    .putt-box .value-add span{
        font-size: 57px;
    }
    .slider.slider-horizontal{
        margin: 0 15px;
    }
    span.plus.controlSlider {
        float: left;
    }
    span.controlSlider:hover{
        background: #7aa425;
    }
    #putt-whisperer{
        width: 1170px;
        margin: 0 auto;
        float: none;
    }
    .putt{
        margin-top: 25px;
    }
    .break {
        padding: 35px 12px;
        text-align: center;
        margin-bottom: 28px;
    }
    
    .break h3{
        font-size: 20px;
        font-weight: 700;
        margin: 8px 0;
    }
    
    .break h1{
        font-size: 44px;
        font-weight: bolder;
        margin-bottom: 14px;
        color: #4a4346;
    }
    
    .break p{
        font-size: 16px;
    }
    
    .clock-watch {
        position: relative;
        width: 400px;
        background: url(../img/watch.png);
        height: 400px;
        background-size: cover;
        margin: 0 auto;
    }
    
    .pin {
        position: absolute;
        left: 18%;
        top: 50%;
        transform: translateY(-39%);
        width: 152px;
        z-index: 1;
    }
    
    .set-angles img {
        width: 100%;
        position: relative;
    }
    
    .plus-minus-angle {
        bottom: -56px;
        position: absolute;
        width: 100%;
    }
    
    .putttime {
        position: absolute;
        left: 50%;
        top: 44%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        text-align: center;
    }

    .putttime-top {
        top: 28%;
    }
    
    .putttime h3 {
        font-size: 34px;
        font-weight: bolder;
        font-family: 'Barlow Condensed', sans-serif;
    }
    
    .putttime h1 {
        font-weight: bolder;
        font-size: 44px;
        margin-top: 2px;
        color: #333333;
    }
    
    .clock-numbers ul .number {
        position: absolute;
        border: 5px solid #c72729;
        border-radius: 50%;
        text-align: center;
        font-size: 41px;
        list-style: none;
        background: #fff;
        color: #000;
        font-weight: 600;
        width: 13%;
        height: 13%;
        padding: 4px 0;
    }
    
    .clock{
        margin-top: 22px;
    }
    .break h3 span {
        font-size: 8px;
        font-weight: 500;
        position: relative;
        top: -12px;
    }
    
    .plus-minus-angle .btn-circle {
        font-size: 20px;
        height: 60px;
        width: 60px;
        text-align: center;
    }
    
    .D-putt-green h3{
        font-weight: 500;
        font-size: 20px;
        margin: 0px 0 0px 0;
    }
    
    .D-putt{
        position: relative;
        margin-top: 8px;
        margin-bottom: 14px;
    }
    
    .value-add{
        /* background: #006ffb; */
        background: blue;
        font-size: 35px;
        font-weight: bolder;
        color: #fff;
        float: left;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        width: 25%;
        position: relative;
        height: 100%;
        text-align: center;
        line-height: 100px;
    }
    
    .select-value .btn-circle {
        font-size: 1em;
        background: #f7f7f6;
        border-radius: 4px;
        line-height: 14px;
        color: #696e75;
        border: 3px solid #c8c8c8;
        cursor: pointer;
        box-shadow: none;
        margin-bottom: 5px;
        margin-left: 3px;
        margin-right: 3px;
        font-size: 26px !important;
        width: 50px;
        height: 40px;
    }
    
    .select-value{
        padding-top: 7px;
        padding-right: 0px;
        text-align: center;
    }
    
   /* .btn-circle {
        background: #f7f7f6;
        border-radius: 4px;
        line-height: 26px;
        color: #696e75;
        border: 3px solid #c8c8c8;
        box-shadow: none;
    }*/
    
    .btn-value:hover, .btn-value:focus, .btn-value.active {
        /* background: #006ffb; */
        background: blue;
        color: #fff;
        /* border: 3px solid #006ffb; */
        border: blue;
        outline: 0;
    }

    .remove-hover:hover {
        background: #f7f7f6 !important;
        color:#696e75 !important;
        border: 3px solid #c8c8c8 !important;
        outline: 0;
        cursor: not-allowed;
    }

    .remove-selected-point:focus{
        background: #f7f7f6 !important;
        color:#696e75 !important;
        border: 3px solid #c8c8c8 !important;
        outline: 0;
    }
    
    .area .btn-value:hover, .area .btn-value:focus, .area .btn-value.active, .area .btn:active:focus {
        background: #ff8102;
        color: #fff;
        border: 3px solid #ff8102;
    }
    
    .green .btn-value:hover, .green .btn-value:focus, .green .btn-value.active, .green .btn:active:focus {
        background:#007100;
        border: 3px solid #007100;
    }
    
    .putt-pin-green{
        margin-top: 16px;
    }
    
    .intro-video .video video {
        width: 100%;
        height: 492px;
    }
    
   .intro-video {
        float: left;
        width: 39%;
        margin-left: 7%;
    }
     
    .intro-video .video video {
        height: 295px;
    }
    
    .tutorial-video .video video {
        height: 295px;
        width: 100%;
    }

    .tutorial-video {
        width: 39%;
        float: right;
        margin-right: 7%;
    }
    
    .user-welcome{
        display: none;
    }
    
    .D-greenReading{
        position: absolute;
        width: 100%;
        bottom: 57px;
    }
    
    .intro-video h3{
        font-size: 22px; 
        margin: 5px;
        font-weight: 600;
    }
    
    .green-reading .read-putt h3 {
        font-size: 32px;
    }
    
    
    
    .tutorial-video h3 {
    font-size: 22px;
    margin: 5px;
    font-weight: 600;
}
    
    .green-reading {
    /* background: url(../img/green-reading.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    padding: 25px 0;
    text-align: center;
    color: #fff;
    margin-top: 12px;
}
    
.D-graph{
   width: 56% !important;
    height: 440px !important;
    float: left;
    margin-top: 24px;
}
    
    .g-slider{
        margin-top: 15px;
    }
    .g-slider li{
        list-style: none;
        padding: 10px;
    }

    .g-head {
        font-size: 18px;
        margin-right: 35px;
        font-weight: 600;
    }
    
    .slider.slider-horizontal {
        width: 80%;
        height: 20px;
    }
    
    /* .g-slider li p span i{
        font-size: 32px;
        margin-right: 10px;
        position: relative;
        top: 5px;
        left: 16px;
    } */
    
    .g-slider li p .g-value{
         font-size: 18px;
         font-weight: 600;
    }

    .ground_background {
        background:#fff;
        width: 100%;
        margin: 0 auto;
        position: relative;
        background-size: cover;
        height:450px;
    }
      
    
    .golf-ball {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
    }
    .golf-ball img {
        width:87%;
    }
    
    .hole-line {
        width: 1px;
        height: 320px;
        position: absolute;
        bottom: 47px;
        left: 50%;
        transform: translate(-50%);
        background-color: #7cf9b4;
    }
    
    .putt-break-line {
        width: 100px;
        width: 186px;
        height: 1px;
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: #000;
        transform: rotate(-16deg);
        transform-origin: right;
    }
    
    .aim-line {
        width: 1px;
        height: 320px;
        position: absolute;
        bottom: 30px;
        left: 10px;
        background-color: #b6b6b6;
        transform: rotate(-33deg);
        transform-origin: bottom;
    }
    
    .track-line {
        position: absolute;
        bottom: 30px;
        left: -43px;
        transform: rotate(1deg);
        transform-origin: bottom center;
        background-image: url(../img/ball-line.png);
        width: 103px;
        height: 368px;
        position: absolute;
        background-size: cover;
    }
    
    .aim-icon {
        background-color: #1d1db7;
        width: 15px;
        height: 15px;
        position: absolute;
        top: -4px;
        background-size: cover;
        left: -4px;
        opacity: 0.8;
        border-radius: 15px;
    }
    
    .aim-hole {
        background-image: url(../img/golf_hole.png);
        width: 24px;
        height: 24px;
        position: absolute;
        top: -8px;
        background-size: cover;
        left: -8px;
        opacity: 0.5;
    }

}





@media (min-width:768px){
    .vSliderBox .vSlider.vGreenSpeed {
        left: 110px;
    }
    .vSliderBox .vSlider.vPASlope {
        left:180px;
    }
    .vSliderBox .vSlider.vPuttTime {
        right: 200px;
    }
    .vSliderBox .vSlider.vPLength {
        right: 130px;
    }
    .puttpower-value{padding-right: 28px;}
}


 /* ipad-pro Landscape*/
 @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
    .intro-video {width: 100%;}
       .tutorial-video {width: 100%;}
    
    .value-add{line-height: 123px;}
    
    .select-value .btn-circle{margin-left: 2px;width: 50px;height: 40px;margin-top: 9px;}
    
    .break h3 {font-size: 22px;font-weight: 700;margin: 14px 0;}
    
    .clock-watch {width: 450px;height: 450px;}
    
    .set-angles img {transition: transform 0.2s linear;transform-origin: 144px 16px;transform-style: preserve-3D;}
    
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
    .intro-video {width: 100%;}
    .tutorial-video {width: 100%;}
    
    .value-add{line-height: 123px;}
    
    .select-value .btn-circle{margin-left: 2px;width: 50px;height: 40px;margin-top: 9px;}
    
    .break h3 {font-size: 22px;font-weight: 700;margin: 14px 0;}
    
    .clock-watch {width: 450px;height: 450px;}
    
    .set-angles img {transition: transform 0.2s linear;transform-origin: 144px 16px;transform-style: preserve-3D;}
    
}

 /* ipad-pro Portrait*/
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)  {
      .putt .break h3 {font-size: 24px;}  
        
        .putt .break {padding: 25px 4px;}
        
        .set-angles .clock-watch {width: 280px;height: 280px;}
        
        .clock-watch .pin {position: absolute;left: 18%;top: 51%;transform: translateY(-50%);width: 108px;}
        
        .clock-watch .clock-numbers ul .number{line-height: 26px;}
        .putt-pin-green.D-putt-green h3 {font-size: 20px;margin: 30px 0 12px 0;}
        .putt  .select-value {width: 75%;padding: 14px 0 16px 0;text-align: center;}
        
        .select-value .btn-circle {font-size: 26px;padding: 13px 0px;margin: 10px 4px 8px 1px;}
        
        
}

/**************** media query for mobile tablet *********************/
/* 
@media only screen and (device-width:1366px) and (orientation: landscape) {
    .tablet-mobile-view  {
        display: none;
    }
   
} */
@media only screen and (max-width: 1366px) and (min-width: 1024px) {

.header .back{
    line-height: 45px;
}

.header{ 
    background: #8fcc15;
    /* background: rgba(139,184,43,1);
    background: -moz-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(139,184,43,1)), color-stop(100%, rgba(115,156,34,1)));
    background: -webkit-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
    background: -o-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
    background: -ms-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
    background: linear-gradient(to right, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);	 */
    padding: 0px;
}

.header h3{
    color: #fff;
    font-size: 20px;
    line-height: 42px;
}

.header h3 sup{
    font-size: 10px;
}

/* .header .profile .profile-details{
    text-align: right;
    margin-top: 3px;
    margin-bottom: -4px;
} */

.header .profile .profile-details img{
    width: 38px;
    border: 4px solid #56790c;
    border-radius: 50px;
    position: absolute;
    right: 2px;
}

.back-icon {
    /*display:none;*/
}


.toggle {
    display: block;
    margin-top: 6px;
    margin-left: 13px;
}

.intro-video .video video{
    width: 100%;
}

/***** signup-login-secion *****/

.user-welcome{
    padding: 6px 0 0 0;
}

.user-welcome .user-login h3{
    padding: 5px 0;
    font-size: 20px;
}

.user-welcome .user-login h3 span{
    font-weight: 500;
    color: #555653;
}

.user-welcome .user-login .G-btn{
    padding: 6px 0;
    font-size: 16px;
    border: 1px solid #96c33a;
    background: rgba(139,184,43,1);
    background: -moz-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(139,184,43,1)), color-stop(100%, rgba(115,156,34,1)));
    background: -webkit-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
    background: -o-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
    background: -ms-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
    background: linear-gradient(to right, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
    -webkit-box-shadow: 2px 14px 20px -10px rgba(115, 156, 33, 0.66);
    -moz-box-shadow: 2px 14px 20px -10px rgba(115, 156, 33, 0.66);
    box-shadow: 2px 14px 20px -10px rgba(115, 156, 33, 0.66);
}


/***** green-reading-secion *****/

.green-reading{
    /* background: url(../img/green-reading.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    padding: 25px 0;
    text-align: center;
    color: #fff;
    margin-top: 12px;
}


.green-reading .read-putt h3{
    font-weight: 700;
    font-size: 16px;
}

.green-reading .read-putt h3 span{
    background: #df9012;
    padding: 10px 30px;
    margin-left: 6px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: inherit;
}
    
.green-reading .read-putt p{
    font-size: 20px;
    text-transform: uppercase;  
    color: #000;
}   
.green-reading .read-putt  label{
    color: #000;
    margin: 15px 0;
} 

/***** tutorial-video-secion *****/

.tutorial-video h3{
    margin: 5px;
    font-weight: 600;
}

.tutorial-video video{
    width: 100%;
}

/***** footer-secion *****/

.footer {
    position: fixed;
    width: 100%;
    /*top: 45px;*/
    background: #fff;
   /* display:none;*/
    border-top: 1px solid #efefef;
}

.footer-toggle-container {
    display:block;
}

.footer .bottom-footer ul{ 
    display: inline;
}

.footer .bottom-footer ul li {
    display: block;
    text-align: left;
    width: 100%;
    line-height: 18px;
    vertical-align: middle;
    padding: 19px 0px;
    padding-left: 33px;
    font-size: 20px;
    border-bottom: 1px solid #dadada;
}

.footer .bottom-footer ul li i{
    color: #676565;
    display:inline-block;
    margin-right:10px;
}

.footer .bottom-footer ul li h3{
    display:inline-block;
}


.bottom-footer {
    text-align: center;
}

.putt-pin-green h3 {
    font-size: 20px;
    margin: 4px 0 4px 0;
} 
.footer .bottom-footer ul li {
    line-height: 28px;
    padding: 10px 0px;
    font-size: 24px;
}
.footer .bottom-footer ul li i {
    font-size: 24px;
}
.select-value .btn-circle{
    font-size: 24px;
    padding: 6px 0;
}
.plus-minus-angle .btn-circle{
    padding: 6px;
}    
.green-reading .read-putt h3{
    font-size: 26px;
}    
.read-putt a{
    font-size: 20px;
    padding: 10px 25px;
    margin-left: 15px;
}
.tutorial-video h3 {
    font-size: 22px;
}
.value-add span {
    width: inherit;
}

.profile{
    width: 50px;
    float: right;
    margin-right: 5px;
}

}

@media screen and (orientation:landscape) {
  #footer.footer{
        position: fixed;
        z-index: 1;
    } 
}



@media screen and (max-width:1024px) {
    
    .footer {
        position: fixed;
    }
    .header-puttbreak-text sup {
        font-size: 8px;
    } 
    button.btn.btn-circle.pull-right.inc-min {
        left: 275px;
    }
    button.btn.btn-circle.pull-left.dec-min {
        left: -35px;
    }
    .header-labels-flex sup {
        top: -14px;
    }
    sup {
        font-size: 11px;
        top: -7px;
    }

    .vSlider .slider.slider-vertical:after {
        width: 33px;
        height: 35px;
        background: #fff;
        content: '';
        position: absolute;
        top: -14px;
        left: 5px;
        z-index: -1;
        border-radius: 0px;
    }
    .vSlider.vPASlope .slider.slider-vertical .slider-selection,
    .vSlider.vPASlope .slider.slider-vertical:after{
        background: #fff;
    }
    .vSlider.vPASlope .slider-track-high{
        background: #fff;
    }

    .vSlider.vGreenSpeed .slider.slider-vertical .slider-selection,
    .vSlider.vGreenSpeed .slider.slider-vertical:after{
        background: #fff;
    }
    .vSlider.vGreenSpeed .slider-track-high{
        background: #fff;
    }

    .g-slider .g-head, .g-slider .g-value {margin-top: 6px;}
    .mp-0{padding: 0;}
    .grassbg{height: 100vh; background: url('../img/icons/grass-texture.png'); background-size: cover;}
    .grassbg canvas{margin: -4px 0;}
    /* .p-0{padding: 0} */
    .slider.slider-horizontal{float: right; margin-right: 46px;width: 30% !important;}
    
    #desktop-header{display: none !important;}
    /* #mobile-header{display: block !important;} */
    #desktop-footer {z-index: -1}
   
    .footer{display: block;position: static;}    
    sup{font-size: 9px;}
    /***** header-secion *****/

  .header .back{line-height: 45px;}

  .header{  /* position: fixed; */height: 36px;background: rgba(24,54,7,.8);top: 0;width: 100%;z-index: 1;}

  .header h3{
  	color: #fff;
      font-size: 20px;
      line-height: 42px;
  }

  .header h3 sup{
      font-size: 10px;
  }

  .header .profile .profile-details{
  	text-align: right;
  }

  .header .profile .profile-details img{
  	width: 35px;
      border: 2px solid #56790c;
      border-radius: 50px;
      position: absolute;
    right: 2px;
  }


  .tutsHeader sup{
    left: -5px;
  }



  .intro-video .video video{
      width: 100%;
  }
  
  /***** signup-login-secion *****/

  .user-welcome{
  	 padding: 6px 0 0 0;
  }

  .user-welcome .user-login h3{
  	padding: 5px 0;
      font-size: 20px;
  }

  .user-welcome .user-login h3 span{
  	font-weight: 500;
    color: #555653;
    padding-left: 6px;
    font-size: 22px;
  }

  .user-welcome .user-login .G-btn{
  	padding: 6px 0;
      font-size: 16px;
      border: 1px solid #96c33a;
      background: rgba(139,184,43,1);
  	background: -moz-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
  	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(139,184,43,1)), color-stop(100%, rgba(115,156,34,1)));
  	background: -webkit-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
  	background: -o-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
  	background: -ms-linear-gradient(left, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
  	background: linear-gradient(to right, rgba(139,184,43,1) 0%, rgba(115,156,34,1) 100%);
  	-webkit-box-shadow: 2px 14px 20px -10px rgba(115, 156, 33, 0.66);
  	-moz-box-shadow: 2px 14px 20px -10px rgba(115, 156, 33, 0.66);
  	box-shadow: 2px 14px 20px -10px rgba(115, 156, 33, 0.66);
  }


  /***** green-reading-secion *****/

  /* .green-reading{
      background: url(../img/green-reading.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 25px 0;
      text-align: center;
      color: #fff;
      margin-top: 12px;
  } */
  .green-reading{  
    background: unset;
    padding: 15px 0;
    text-align: center;
    /* color: #fff; */
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    margin: 8px 12px;
}
  

  .green-reading .read-putt h3{
      font-weight: 700;
      font-size: 20px;
  }

  .green-reading .read-putt h3 span{
      background: #df9012;
      padding: 10px 14px;
      margin-left: 6px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      font-weight: inherit;
  }
  .green-reading .read-putt label{
      margin: 15px 0;
  }
  
  /***** tutorial-video-secion *****/

  .tutorial-video h3{
      margin: 5px;
      font-weight: 600;
  }

  .tutorial-video video{
     width: 100%;
  }

  /***** footer-secion *****/

  .footer {
        width: 100%;
        bottom: 0;
        border-top: 1px solid #efefef;
    }
    .bottom-footer {
        text-align: center;
    }
  .footer .bottom-footer ul{ 
     display: inline;
  }

  .footer .bottom-footer ul li {
        display: inline-block;
        text-align: center;
        width: 25%;
        line-height: 18px;
        vertical-align: middle;
        padding: 11px 0px;
    }

    .footer .bottom-footer ul li i{
        color: #676565;
        font-size: 18px;
        padding-bottom: 0px;
    }
        
    .footer .bottom-footer ul li .fa-cog, .footer .bottom-footer ul li .fa-home{
        font-size: 20px;
    }
        
    .footer .bottom-footer ul li i:hover,.footer .bottom-footer ul li i:focus,.footer .bottom-footer ul li i.active{
        color:#7fab25;
    }
    .toggle {
        display:none;
    }

    .bottom-footer {
        text-align: center;
    }
    
    .p-r-none{
        padding-right: 0;
    }

  /*=====putt whisperer===*/
  .break {
        padding: 7px 4px;
        border-radius: 4px;
        text-align: center;
        margin-top: 10px;
        font-family: 'Barlow Condensed', sans-serif;
    }
    .break p{
        font-size:13px;
        color:#878885;
    }
        
    .break h1, .break h3 {
        font-weight: 600;
        /* font-size: 39px; */
        font-size: 44px;
    }
        
    .break h3{
        font-size:18px;
    }
        
    .break h3 span,.break h1 span{
        font-size:8px;
        font-weight:normal;
        position: relative;
        top:-8px;
    }
    .break h1 span{
        font-size:17px;
        font-weight:bold;
        vertical-align: text-top;
        top: 2px;
    }
        
.clock{
    position: relative;
    margin-top: 5px;
    margin-right: 5px;
}
    
.pin{
    position: absolute;
    left: 16%;
    top: 50%;
    transform: translateY(-50%);
    width: 86px;
    z-index: 1;
}
.set-angles img{
    width: 95%;
    position: relative;;
}
    
.putt-watch-panel{
    display: flex;
}
    
.set-angles{
    /* display: flex;
    align-items: center; */
    padding:0;
    margin-right: 10px;
}
    
.putttime h3{font-size:26px;font-weight:600;     font-family: 'Barlow Condensed', sans-serif;}
.putttime{
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translateX(-50%);
    text-align:center;
}

.putttime-top {
    top: 13%;
}
    
.putttime h3 span{
    vertical-align: text-top;
    top: 2px;
    font-size:9px
}
    
.putttime h1 {
    font-weight: 600;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 44px;
}
    
.putt-pin-green .putt{
    background:#fff;
    border-radius:4px;
    display: flex;
}
    
.value-add{
    /* background:#006ffb; */
    background: blue;
    font-size:40px;
    font-weight:bolder;
    color:#fff;
    float: left;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    padding:5px; 
    width:25%;
    position: relative;
}
    
.value-add
.select-value{
    padding:2px 3px;
    float: left;
    width:75%;
    padding: 5px;
}
    
.btn-value {
	font-size:1em;
	background: #f7f7f6;	
    border-radius:4px;
	height:15%;
	width:15%;
	line-height:26px;
	color:#696e75;
    border:3px solid #c8c8c8;
    cursor: pointer;
    box-shadow:none;
    margin-bottom:5px;
}
    
.btn-value:hover, .btn-value:focus,.btn-value.active{
    /* background: #006ffb; */
    background: blue;
	color:#fff;
    border:3px solid blue;
    /* border: blue; */
    outline:0;
}

.remove-hover:hover {
    background: #f7f7f6 !important;
    color:#696e75 !important;
    border: 3px solid #c8c8c8 !important;
    outline: 0;
    cursor: not-allowed;
}

.remove-selected-point:focus{
    background: #f7f7f6 !important;
    color:#696e75 !important;
    border: 3px solid #c8c8c8 !important;
    outline: 0;
}
    
.add-extra{
    height: 15%;
    width: 15%;
}
    
.select-value {
    width:75%;
    padding:10px 0 6px 0;
    text-align: center;
}
.value-add{position: relative;}
.value-add span{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /*width: 50%;*/
}
    
.section.putt-pin-green{
    vertical-align: text-top;
    top: 2px;
    font-size: 11px;
}
    
.area .btn-value:hover,  .area .btn-value:focus,  .area .btn-value.active,  .area .btn:active:focus{
    background: #ff8102;
    color: #fff;
    border: 3px solid #ff8102;
}
    
.green .btn-value:hover,  .green .btn-value:focus,  .green .btn-value.active,  .green .btn:active:focus{
    background: #007100;
    border: 3px solid #007100;
}
    
    /******** graph ************/
    .slider.slider-horizontal {
        width: 35%;
        height: 20px;
        margin-left: 8px;
        margin-top: 4px;
    }   
    .g-slider{
        margin: 5px 10px;
    }    

    .g-slider li P{
        font-size: 14px;
    }    
    .g-slider li P sup{
        font-size: 10px;  
    } 
    /* .g-slider li P i{
        font-size: 32px;
        margin-left: 5px;
        position: relative;
        top: -4px;
        cursor: pointer;
    }        */

    /* .g-slider li P i:hover{
        color: forestgreen;
    }     */
        
    .g-slider li P .g-value{
        margin-left: 8px;
        font-weight: 600;
        margin-right: 3px;
    }

    .g-slider li P .minus{
    position: absolute;
    right: 10px;
    }   
        
    .g-slider li P .plus{
        position: absolute;
        /* right: 45px; */
        right: 46%;
    }   
    
    .clock-watch {
        position: relative;
        width: 209px;
        background: url(../img/watch.png);
        height: 209px;
        background-size: cover;
        margin: 0 auto;
        top: 5px;
        left: -4px;
    }
        
    .plus-minus-angle {
        bottom: -30px;
        position: absolute;
        width: 100%;
        z-index: 1;
    }    

    .dropdown-menu{
        padding: 25px;
    }    
        
    .dropdown-menu a{
        display: block;
    }
    .dropdown-menu {
        padding: 6px;
        right: 0;
        left: inherit;

    }
        
    .set-angles img {
        transition: transform 0.2s linear;
        transform-origin: 74px 8px;
        transform-style: preserve-3D;
    }
    .D-setting {
        padding: 12px 10px;
    }
    .header-puttbreak-text sup {
        font-size: 8px;
    }
    .putttime h3 span {
        vertical-align: text-top;
        top: 5px;
        font-size: 9px;
        position: relative;
    }
    

}


@media screen 
  and (device-width: 1024px) 
    and (device-height: 768px) {
    button.btn.btn-circle.pull-right.inc-min {
        left: 261px;
    }
    button.btn.btn-circle.pull-left.dec-min {
        left: -35px;
    }
   .clock-watch {
        width: 255px;
        height: 255px;
    }  
        
    .clock-numbers ul .number {
        padding: 3px 0;
    }  
        
    .pin {
        left: 23%;
        top: 51%;
     }
    .select-value .btn-circle {
        margin-left: 6px;
        width: 106px;
        height: 46px;
        margin-top: 9px;
    }
        
    .footer .bottom-footer ul li {
        line-height: 36px;
    }
}



@media screen 
  and (device-width: 768px) 
  and (device-height: 1024px) {
        
    .intro-video .video video {
        width: 100%;
        height: 36vh;
    }

    .tutorial-video video {
        width: 100%;
        /* height: 36vh; */
    }
    .break {
        padding: 22px 7px;
        margin-top: 15px;
    }    
    .clock-watch {
        position: relative;
        width: 275px;
        background: url(../img/watch.png);
        height: 275px;
        background-size: cover;
        margin-top: 12px;
        margin: 0 auto;
    }
        
    .putt-pin-green h3 {
        font-size: 24px;
        margin: 18px 0 7px 0;
    }
        
    .clock-numbers ul .number {
        border: 3px solid #a94442;
        font-size: 33px;
        padding: 3px 0;
        line-height:27px;

    }   
    .pin {
        position: absolute;
        left: 16%;
        top: 52%;
        transform: translateY(-50%);
        width: 108px;
    }
    .plus-minus-angle .btn-circle{
    padding: 3px;
    }
    .select-value {
        padding: 12px 0px 8px 5px;
    }
    .select-value .btn-circle{
        padding: 10px 0;
        margin: 4px 0;
        font-size: 24px;
    }
    .footer .bottom-footer ul li i {
        font-size: 28px;
        padding-bottom: 6px;
    }
    .select-value {
        padding: 12px 0px 12px 5px;
    }
    .clock {
        position: relative;
        margin-top: 10px;
    }
    .set-angles img {
        transition: transform 0.2s linear;
        transform-origin: 94px 9px;
        transform-style: preserve-3D;
    }
    .header-puttpower-text sup {
        font-size: 15px;
    }
    .header-labels-flex sup {
        top: -11px;
    }
    .putttime {
        left: 48%;
        top: 39%;
    }
    .putttime-top {
        top: 19%;
        left: 50%;
    }
      
}

@media screen and (max-width: 767px) { 
    button.btn.btn-circle.pull-right.inc-min {
        left: 207px;
    }
    
    .clock{
        margin-top: 15px;
    }
    .paSlopeValue{
        background: #ff8102;
        width: 20%;
        font-weight: bolder;
        color: #fff;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        font-size: 33px;
        justify-content: center;
        align-items: center;
        display: flex;
    }


    .loginFormContainer{
        display: block;
    }

    .putt-pin-green h3 span {
        font-size: 10px;
    }
    .vSlider .slider.slider-vertical {
        height: 127px;
    }
    .slider.slider-vertical .slider-tick, .slider.slider-vertical .slider-handle {
        margin-top: -14px;
    }

    div#desktop-footer {
        display: none;
    }
    
    .loginBox{
        min-width: 100%;
        padding: 15px;
    }
    .loginFormContainer{
        padding: 15px;
        margin: 3px; /* added the margin container view fix 17-7-19*/
        
    }
    html, body{
        overflow-x: hidden;
    }
    body{
        padding-bottom: 50px;
    }
    #menu .menu-btn a h3 {
        font-size: 20px;
        font-family: 'Barlow Condensed', sans-serif;
        display: block;
        height: 24px;
    }
    .setting {
        margin: 0px;
    }
}
@media screen and (min-width: 768px) { 
     .loginFormContainer{
        display: block;
        padding: 15px;
        margin: 3px; /* added the margin container view fix 17-7-19*/
    }
}
@media screen and (min-width: 800px) and (max-width: 867px){ 
    button.btn.btn-circle.pull-right.inc-min {
        left: 204px;
    }
    
    .clock{
        margin-top: 15px;
    }
    .paSlopeValue{
        background: #ff8102;
        width: 20%;
        font-weight: bolder;
        color: #fff;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        font-size: 33px;
        justify-content: center;
        align-items: center;
        display: flex;
    }


    .loginFormContainer{
        display: block;
    }

    .putt-pin-green h3 span {
        font-size: 10px;
    }
    .vSlider .slider.slider-vertical {
        height: 127px;
    }
    .slider.slider-vertical .slider-tick, .slider.slider-vertical .slider-handle {
        margin-top: -14px;
    }

    div#desktop-footer {
        display: none;
    }
    
    .loginBox{
        min-width: 100%;
        padding: 15px;
    }
    .loginFormContainer{
        padding: 15px;
        margin: 3px; /* added the margin container view fix 17-7-19*/
        
    }
    html, body{
        overflow-x: hidden;
    }
    body{
        padding-bottom: 50px;
    }
    #menu .menu-btn a h3 {
        font-size: 20px;
        font-family: 'Barlow Condensed', sans-serif;
        display: block;
        height: 24px;
    }
    .setting {
        margin: 0px;
    }
}
@media screen and (max-width:480px) {
    button.btn.btn-circle.pull-right.inc-min{
        left: 167px;
    }
    button.btn.btn-circle.pull-left.dec-min{
        left: -2px;
    }
    /* canvas#canvas{
        max-height: 400px;
        margin: 0 auto;
        display: block;
    } */
    .green-reading.letsgo{
        padding: 5px 0;
    }
    .tutorial-video video{
        /* height: 25vh; */
    }
    .g-slider li P .plus{
        right: 50%;
    }
    .feature ul li{
        margin-bottom: 5px;
    }
    .g-slider{
        list-style-type: none;
        margin: 5px 0;
        display: inline-block;
        width: 100%;
    }
    .putt-watch-panel{
        margin-bottom: 20px;
    }
    .footer {
        position: fixed;
        width: 100%;
        bottom: 0;
    }
    .bottom-footer {
        text-align: center;
    }
    .break p{
        font-size:21px;
    }
    /* .break h1,.break h3{
        font-weight:bolder;
        font-size:50px;
    } */
    .break h3 {
        font-size: 28px;
    }
    .break h3 span{
        font-size:8px;
        font-weight:normal;
        position: relative;
        top:-16px;
    }
    .value-add{
        width: 20%;
        font-size: 26px;

    }
    .select-value {
        width: 80%;
        padding: 3px;
        padding: 5px 5px 0 5px;
    }
    .btn-value {
        font-size:1.4em;
        background: #f7f7f6;	
        border-radius:4px;
        height:15%;
        width:15%;
        line-height:14px;
        color:#696e75;
        border:3px solid #c8c8c8;
        cursor: pointer;
        box-shadow:none;
        margin-bottom:5px;
    }
    /*.btn-value:hover, .btn-value:focus,.btn-value.active,.btn:active:focus{
        background: #006ffb;
        color:#fff;
        border:3px solid #006ffb;
        outline:none;
    }*/
    .value-add span {
        position: absolute;
        /*left: 37%;*/
    }
    .area .value-add{
        font-size:24px;
    }
    .putt-pin-green h3 span {
        font-size: 10px;
    }  
        
    .putt-pin-green h3 {
        font-size: 17px;
        padding: 6px 0;
    }
    .btn-circle{
    padding: 5px;
    }  
    .clock-numbers ul .number {
        font-size: 30px;
        padding: 0px 0 0 0;
        font-weight: 700;
        line-height: 25px;
    }
    .clock-numbers ul .twelve {
        line-height: 25px;
    }

    
    
}

@media screen 
  and (device-width: 411px) 
  and (device-height: 731px) {
    .intro-video {
        float: left;
        width: 100%;
        margin-bottom: 8px;
    }
    .putt-box .value-add span {
        font-size: 39px;
    }
    button.btn.btn-circle.pull-right.inc-min {
        left: 187px;
    }
    button.btn.btn-circle.pull-left.dec-min {
        left: -6px;
    }
    .intro-video video {
        width: 100%;
        /* height: 30vh; */
    }
    .tutorial-video {
        float: left;
        width: 100%;
        margin-bottom: 8px;
    }
    .tutorial-video video {
        width: 100%;
        /* height: 30vh; */
    }
    .putt-pin-green h3 {
        font-size: 19px;
        padding: 15px 0 5px 0;
    }
    .break {
    padding: 12px 4px;
    }
        
    .clock-watch {
        width: 220px;
        height: 220px;
        top: 0px;
        left: -5px;
    }
        
    .select-value .btn-circle {
        padding: 6px 0;
    }
        
    .pin {
        left: 17.5%;
        top: 52%;
    }
        
    .set-angles img {
        transition: transform 0.2s linear;
        transform-origin: 80px 9px;
        transform-style: preserve-3D;
    }
    .set-angles img {
        width: 100%;
        position: relative;
    }
    .putttime {
        left: 50%;
        top: 36%;
    }
    .putttime-top {
        top: 18%;
    }

    .break {
        padding: 8px 4px;
    }
    h3.bold-font.puttlength-calc {
        margin-top: -16px;
    }
    .putt-pin-green h3.puttlength-calc, .putt-pin-green h3.pinareaslope-calc, .putt-pin-green h3.greenspeed-calc {
        font-weight: 500;
        font-size: 20px;
        padding: 8px 0 5px;
    }
    .select-value {
        padding: 2px 5px 0 5px;
    }
    .select-value input.btn.btn-circle.btn-value {
        padding: 9px 0;
    }

}
@media (max-height: 700px){
    /* canvas#canvas{
        max-height: 400px;
        display: block;
        margin: 0 auto;
    } */
  }
  @media (max-height: 570px){
    /* canvas#canvas{
        max-height: 320px;
        display: block;
        margin: 0 auto;
    } */
  }
@media screen 
  and (device-width: 411px) 
    and (device-height: 823px) {
        
        

  .putt-pin-green h3 {
    font-size: 19px;
    padding: 15px 0 5px 0;
}
.break {
    padding: 12px 4px;
}
      
.clock-watch {
    width: 240px;
    height: 240px;
    top: 12px;
    left: 0px;
} 
button.btn.btn-circle.pull-right.inc-min {
    left: 202px;
}
button.btn.btn-circle.pull-left.dec-min {
    left: -1px;
}
        
.select-value .btn-circle {
    padding: 10px 0;
}
.pin {
    position: absolute;
    left: 19%;
    top: 52%;
    transform: translateY(-50%);
    width: 94px;
}
.set-angles img {
    width: 95%;
    position: relative;
}
        
.set-angles img {
    transition: transform 0.2s linear;
    transform-origin: 79px 9px;
    transform-style: preserve-3D;
}    
.putt-pin-green h3.puttlength-calc, .putt-pin-green h3.pinareaslope-calc, .putt-pin-green h3.greenspeed-calc {
    font-size: 24px;
}    

.putttime h1 {
    font-weight: 600;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 44px;
}
.putttime {
    top: 40%;
}
.putttime-top {
    top: 16%;
}
        
}

@media screen 
  and (device-width: 411px) 
  and (device-height: 823px) {
    .intro-video {float: left;width: 100%;margin-bottom: 8px;}
    .intro-video video {width: 100%;/* height: 33vh; */}
    .tutorial-video {float: left;width: 100%;margin-bottom: 8px;}
    .tutorial-video video {width: 100%;/*height: 33vh; */}

}


@media screen 
  and (device-width: 360px) 
    and (device-height: 640px) {
    .putt-pin-green h3 {font-size: 14px;padding: 8px  0 5px;}
    .set-angles img {transition: transform 0.2s linear;transform-origin: 74px 8px;transform-style: preserve-3D;}
    .select-value input.btn.btn-circle.btn-value {
        padding: 6px 0;
        font-size: 29px;
        font-weight: 600;
    }
    button.btn.btn-circle.pull-left.dec-min {
        left: -4px;
    }
    button.btn.btn-circle.pull-right.inc-min {
        left: 171px;
    }
    .clock-watch {
        width: 209px;
        height: 208px;
        top: 3px;
        left: -4px;
    }
    .break {
        padding: 1px 4px;
    }
    h3.bold-font.puttlength-calc {
        margin-top: -14px;
    }
    .putt-pin-green h3.puttlength-calc, .putt-pin-green h3.pinareaslope-calc, .putt-pin-green h3.greenspeed-calc {
        font-weight: 500;
        font-size: 17px;
        padding: 2px 0 5px;
    }
    .select-value {
        padding: 2px 5px 0 5px;
    }
    .clock-numbers ul .number {
        font-size: 30px;
        padding: 0px 0 0 0;
        font-weight: 700;
        line-height: 26px;
    }
    .clock-numbers ul .twelve {
        line-height: 25px;
    }
    .pin {
        left: 16%;
        top: 51%;
    }
    .set-angles img {
        width: 97%;
        position: relative;
    }
    .putttime {
        top: 33%;
    }
    .putttime-top {
        top: 11%;
    }
}
@media (max-width:320px){
    li.menu-btn h3 {font-size: 14px;}
    .green-reading.D-greenReading > .container{padding: 0;}
    .green-reading h3 a.btn {padding: 9px 15px;font-size: 16px;     height: 40px;
        width: 150px;}
    .main-container{padding-bottom: 0;}
    .slider.slider-horizontal {width: 28%;}
    .g-slider {margin: 5px 0;list-style-type: none;}
    .g-slider li P .plus {right: 44%;}
    .slider.slider-horizontal {width: 22% !important;}
    .vSlider .slider.slider-vertical {height: 106px;}
    .btn-value{font-size: 1rem;}
    .btn-circle {padding: 3px;width: 34px;height: 30px;text-align: center;}
}

@media screen and (max-width:320px) and (orientation: portrait) { 
    .clock {
        width: 182px;
    }
    .break h1 {
        font-weight: 600;
        font-size: 39px;
    }
    .btn-circle{
        padding:2px;
    }
    .putt-pin-green h3 {
        font-size: 13px;
        margin-bottom:0;
        padding:4px 0;
    }
    .area .value-add {
        font-size: 22px;
    }
    .putt-pin-green h3 span{  
        font-size: 10px;
    }
    .clock-watch {
        width: 205px;
        height: 205px;
        margin-top: 5px;
    }
        
    .putttime {
       left: 58%;
    }
        
    .plus-minus-angle {
        bottom: -22px;
    }
    .clock-numbers ul .number {
        position: absolute;
        border-radius: 50%;
        text-align: center;
        font-size: 31px;
        list-style: none;
        background: #fff;
        color: #000;
        font-weight: 600;
        width: 18%;
        height: 18%;
        padding: 0px 0;
    }
    button.btn.btn-circle.pull-left.dec-min {
        left: 4px;
        top: -37px;
    }
    button.btn.btn-circle.pull-right.inc-min {
        left: 152px;
        top: -37px;
    }
    .plus-minus-angle .btn-circle{ 
        font-size: 12px;
        padding: 2px 4px;
    }
    
    .set-angles img {
        width: 95%;
        position: relative;
    }
        
    .set-angles img {
        transition: transform 0.2s linear;
        transform-origin: 62px 7px;
        transform-style: preserve-3D;
    } 
    .D-setting {
        padding: 12px 10px;
    }
        
    .D-form p {
        display: inline;
        margin-right: 0;
    } 
    }

@media screen 
  and (device-width: 320px) 
  and (device-height: 568px) {
    .intro-video {float: left;width: 100%;margin-bottom: 8px;}
    .intro-video video {width: 100%;/* height: 25vh; */}
    .tutorial-video {float: left;width: 100%;margin-bottom: 8px;}
    .tutorial-video video {width: 100%;/* height: 25vh; */}
    .clock {width: 163px;}
    .break h1 {font-weight: bolder;font-size: 38px;}
    .btn-circle{padding:2px;}
    .putt-pin-green h3 {font-size: 13px;margin-bottom:5px;}
    .area .value-add {font-size: 22px;}
    .pin {
        left: 18%;
        top: 52%;
    }
    .set-angles img {
        width: 84%;
        position: relative;
    }
    .break h3 {
        font-size: 20px;
    }
    .break p {
        font-size: 18px;
    }
    .select-value input.btn.btn-circle.btn-value {
        padding: 0px 0;
        font-size: 26px;
        font-weight: 600;
    }
    .clock-watch {
        width: 217px;
        height: 217px;
    }
    .break {
        padding: 1px 4px;
    }
    h3.bold-font.puttlength-calc {
        margin-top: -14px;
    }
    .putt-pin-green h3.puttlength-calc, .putt-pin-green h3.pinareaslope-calc, .putt-pin-green h3.greenspeed-calc {
        font-weight: 500;
        font-size: 12px;
        padding: 0px 0 5px;
    }
    .select-value {
        padding: 2px 5px 0 5px;
    }
    h3.bold-font.puttlength-calc {
        margin-top: -14px;
    }
    .putt-pin-green h3.pinareaslope-calc {
        margin-bottom: -4px;
    }
    .putt-pin-green h3.greenspeed-calc {
        margin-bottom: -3px;
    }
    .clock-numbers ul .number {
        position: absolute;
        border-radius: 50%;
        text-align: center;
        font-size: 26px;
        list-style: none;
        background: #fff;
        color: #000;
        font-weight: 700;
        width: 15%;
        height: 15%;
        padding: 0px 0;
        line-height: 22px;
    }
    .clock-watch {
        width: 194px;
        height: 194px;
        top: 0px;
        left: -9px;
    }
    button.btn.btn-circle.pull-left.dec-min {
        left: -1px;
        top: -46px;
    }
    button.btn.btn-circle.pull-right.inc-min {
        right: 148px;
        top: -46px;
    }
    #menu .menu-btn a h3 {
        font-size: 18px;
    }
    .putttime {
        left: 56%;
        top: 40%;
    }
    .putttime.putttime-top {
        left: 97px;
        top: 46px;
    }
    .putttime h1 {
        margin-top: -10px;
    }
}

@media screen 
  and (device-width: 414px) 
  and (device-height: 736px) {

    .clock-watch {
        width: 220px;
        height: 220px;
        top: 1px;
        left: -7px;
    }
    putt-box .value-add span {
        font-size: 39px;
    }
    button.btn.btn-circle.pull-right.inc-min {
        left: 180px;
    }
    button.btn.btn-circle.pull-left.dec-min {
        left: -1px;
    }
    /* Iphone 6 to 7 plus start */
    .ground_background {
        background:#fff;
        /* background: url(../img/golf_background.jpg) no-repeat; */
        /* width: 400px; */
        width: 100%;
        /* height: 600px; */
        margin: 0 auto;
        position: relative;
        background-size: cover;
        height: 528px;
    }
    .ground_background .track-line{ 
        background-image: url(../img/ball-line-new-1.png);
        bottom: 7%;
        right: 38%;
        transform: rotate(-1deg);
        transform-origin: bottom center;
        width: 120px;
        height: 413px;
        position: absolute;
        background-size: cover;
    }
    
    .ground_background .aim-hole {
        background-image: url(../img/hole-2.png);
        width: 36px;
        height: 36px;
        position: absolute;
        top: -20px;
        background-size: cover;
        left: -18px;
        opacity: 0.5;
    }
    
    .ground_background .aim-icon {
        background-color: #1d1db7;
        width: 5px;
        height: 5px;
        position: absolute;
        top: -2px;
        background-size: cover;
        left: -2px;
        opacity: 0.8;
        border-radius: 15px;
    }
    
    .ground_background .hole-line{
        width: 1px;
        height: 360px;
        position: absolute;
        bottom: 47px;
        left: 50%;
        transform: translate(-50%);
        background-color: #7cf9b4;
    }
    .ground_background .golf-ball img {
       width: 14.5px;
        /* left: 6px; */
        position: absolute;
        left: 48%;
        right: 50;
        top: 91%;
        bottom: 0;
    }
    .ground_background .hole-line .putt-break-line{
        width: 204px;
        height: 1px;
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: #000;
        transform: rotate(-16deg);
        transform-origin: right;
    }
    .ground_background .aim-line{
        width: 1px;
        height: 360px;
        position: absolute;
        bottom: 8.5%;
        left: 49%;
        background-color: #b6b6b6;
        transform: rotate(-32deg);
        transform-origin: bottom;
    }
    
    /*Slider (Start) */
    .graphical-view-graph{
        display: none;
        width: 0% !important;
        height: 0% !important;
        float: left;
        margin-top: 24px;
    }
        
    .graphical-view-slider .graph-slides{
        float: right;
        width: 100%;
    }
    .graphical-view-slider .graph-slides .graph-input{
        margin: 0 auto;
        position: relative;
    }
        /* .graphical-view-slider .g-slider{
            margin-top: 0px;
        } */
        .graphical-view-slider .g-slider li{
            list-style: none;
            padding: 0px;
        }
    
        .graphical-view-slider .g-head {
            font-size: 12px;
            margin-right: 4px;
            font-weight: 600;
        }
        
        .graphical-view-slider .slider.slider-horizontal {
            height: 20px; margin-right: 60px; width: 30%;
        }
        
        /* .graphical-view-slider .g-slider li p span i{
            font-size: 32px;
            margin-right: 10px;
            position: relative;
            top: 5px;
            left: 16px;
        } */
        
        .graphical-view-slider .g-slider li p .g-value{
             font-size: 12px;
             font-weight: 600;
        }

         /* Iphone 6 to 7 plus end */

.intro-video {
	float: left;
    width: 100%;
    margin-bottom: 8px;
}
.intro-video video {
    width: 100%;
    /* height: 25vh; */
}
.tutorial-video {
	float: left;
    width: 100%;
    margin-bottom: 8px;
}
.tutorial-video video {
    width: 100%;
    /* height: 32vh; */
}
.btn-circle {
    padding: 10px;
}
      .break p {
    font-size: 13px;
}
.plus-minus-angle {
  bottom: -46px;
}
.putt-pin-green h3 {
    font-size: 17px;
    padding: 7px 0;
}
.plus-minus-angle .btn-circle{ 
    padding: 6px;
}
.plus-minus-angle {
    bottom: -34px;
    z-index: 1;
}

.break p {
    font-size: 22px;
}
.pin {
    left: 21%;
}
.putt-pin-green h3.puttlength-calc, .putt-pin-green h3.pinareaslope-calc, .putt-pin-green h3.greenspeed-calc {
    font-size: 20px;
}
.putt-pin-green h3 {
    padding: 2px 0 5px;
}
h3.bold-font.puttlength-calc {
    margin-top: -9px;
}
.putttime {
    left: 50%;
    top: 36%;
}
.putttime h1 {
    font-weight: 600;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 44px;
}
.putttime.putttime-top {
    top: 17%;
}
.putttime h3 {
    font-size: 28px;
}
}

@media screen 
  and (device-width: 375px) 
  and (device-height: 812px) {
    .clock-watch {
        width: 220px;
        height: 220px;
        top: 4px;
        left: 0px;
    }
    .select-value input.btn.btn-circle.btn-value {
        font-size: 31px;
    }
    button.btn.btn-circle.pull-left.dec-min {
        left: 7px;
        top: -41px;
    }
    button.btn.btn-circle.pull-right.inc-min {
        left: 175px;
        top: -41px;
    }
.bg-light-green {
    padding: 0px;
}

.g-slider li P .minus{
    right: 0;
}
/* Iphone x graph start */

.ground_background {
	background:#fff;
    /* width: 400px; */
    width: 100%;
    /* height: 600px; */
    margin: 0 auto;
    position: relative;
    background-size: cover;
	height: 528px;
}
.ground_background .track-line{ 
	background-image: url(../img/ball-line-new-1.png);
    bottom: 7%;
    right: 37%;
    transform: rotate(2deg);
    transform-origin: bottom center;
    width: 119px;
    height: 420px;
    position: absolute;
    background-size: cover;
}

.ground_background .aim-hole {
    background-image: url(../img/hole-2.png);
    width: 24px;
    height: 24px;
    position: absolute;
    top: -12px;
    background-size: cover;
    left: -12px;
    opacity: 0.5;
}

.ground_background .aim-icon {
	background-color: #1d1db7;
    width: 5px;
    height: 5px;
    position: absolute;
    top: -2px;
    background-size: cover;
    left: -2px;
    opacity: 0.8;
    border-radius: 15px;
}

.ground_background .hole-line{
	width: 1px;
    height: 330px;
    position: absolute;
    bottom: 47px;
    left: 50%;
    transform: translate(-50%);
    background-color: #7cf9b4;
}
.ground_background .golf-ball img {
    width: 14.5px;
    /* left: 6px; */
    position: absolute;
    left: 48%;
    right: 50%;
    top: 91%;
    bottom: 0
}
.ground_background .hole-line .putt-break-line{
	width: 192px;
    height: 1px;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #000;
    transform: rotate(-16deg);
    transform-origin: right;
}
.ground_background .aim-line{
	width: 1px;
    height: 330px;
    position: absolute;
    bottom: 8.5%;
    left: 49%;
    background-color: #b6b6b6;
    transform: rotate(-33deg);
    transform-origin: bottom;
}

/*Slider (Start) */
.graphical-view-graph{
	display: none;
    width: 0% !important;
    height: 0% !important;
    float: left;
    margin-top: 24px;
}
    
.graphical-view-slider .graph-slides{
    float: right;
    width: 100%;
}
.graphical-view-slider .graph-slides .graph-input{
    margin: 0 auto;
    position: relative;
}
    /* .graphical-view-slider .g-slider{
        margin-top: 0px;
    } */
    .graphical-view-slider .g-slider li{
        list-style: none;
        padding: 0px;
    }

    .graphical-view-slider .g-head {
        font-size: 12px;
        font-weight: 600;
    }
    
    .graphical-view-slider .slider.slider-horizontal {
       
        height: 20px;
    }
    
    /* .graphical-view-slider .g-slider li p span i{
        font-size: 32px;
        margin-right: 10px;
        position: relative;
        top: 5px;
        left: 16px;
    } */
    
    .graphical-view-slider .g-slider li p .g-value{
         font-size: 12px;
         font-weight: 600;
    }

/* Iphone x graph end */


.intro-video {
	float: left;
    width: 100%;
    margin-bottom: 8px;
}
.intro-video video {
    width: 100%;
    /* height: 33vh; */
}
.tutorial-video {
	float: left;
    width: 100%;
    margin-bottom: 8px;
}
.tutorial-video video {
    width: 100%;
    /* height: 33vh; */
}

.plus-minus-angle {
    bottom: -45px;
}
.select-value {
    padding: 10px 5px 4px 5px;
}
.select-value .btn-circle {
    padding: 10px 0px;
}
.putt-pin-green h3.puttlength-calc, .putt-pin-green h3.pinareaslope-calc, .putt-pin-green h3.greenspeed-calc {
    font-size: 23px;
}

.putttime h1 {
    font-weight: 600;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 44px;
}
      
}


@media screen and (max-width:375px) { 
    .puttbreak-value {
        font-size: 45px;
    }
    .puttpower-value {
        font-size: 45px;
    }
    .header-puttbreak-text, .header-puttpower-text {
        font-size: 24px;
    }
    .right_center {
        font-size: 18px;
    }
    .putt-break.puttpower-value sup {
        top: 6px;
    }
}

@media screen and (max-width:360px) {

.puttbreak-value {
    font-size: 40px;
}
.header-puttbreak-text, .header-puttpower-text {
    font-size: 24px;
}
.puttpower-value {
    font-size: 40px;
}
}

@media screen and (max-width:320px) {

    .puttbreak-value {
        font-size: 37px;
    }
    .header-puttbreak-text, .header-puttpower-text {
        font-size: 21px;
    }
    .puttpower-value {
        font-size: 37px;
    }
    .right_center {
        font-size: 16px;
    }
    .putt-break.puttpower-value sup {
        top: 3px;
    }
    span.slider-value-container {
        bottom: -41px;
        font-size: 35px;
    }
    .vSliderBox .vSlider.vGreenSpeed {
        left: 11px;
    }
    .vSliderBox .vSlider.vPASlope {
        left: 77px;
    }
    .vSliderBox .vSlider.vPuttTime {
        right: 94px;
    }
    .vSliderBox .vSlider.vPLength {
        right: 36px;
    }
    span.slider-text-container {
        font-size: 19px;
    }
    .vPLength > .slider-text-container {
        bottom: 37px;
        left: -24px;
    }
    .vPuttTime > .slider-text-container {
        bottom: 30px;
        left: -19px;
    }
    .vPASlope > .slider-text-container {
        bottom: 45px;
        left: -32px;
    }
    .vGreenSpeed > .slider-text-container {
        bottom: 34px;
        left: -22px;
    }
    }


    @media only screen and (device-width : 375px) and (device-height : 667px) {
        .clock-watch {
            left: -7px;
            top: 1px;
        }
        .putt-box .value-add span {
            font-size: 35px;
        }
        .putttime.putttime-top {
            top: 15%;
        }
        
        
        .select-value input.btn.btn-circle.btn-value {
            padding: 8px 0;
        }
        button.btn.btn-circle.pull-left.dec-min {
            left: 1px;
        }
        button.btn.btn-circle.pull-right.inc-min {
            left: 168px;
        }
        .select-value input.btn.btn-circle.btn-value {
            font-size: 31px;
        }
        .break {
            padding: 1px 4px;
        }
        h3.bold-font.puttlength-calc {
            margin-top: -14px;
        }
        .putt-pin-green h3.puttlength-calc, .putt-pin-green h3.pinareaslope-calc, .putt-pin-green h3.greenspeed-calc {
            font-weight: 500;
            font-size: 17px;
            padding: 2px 0 5px;
        }
        .select-value {
            padding: 2px 5px 0 5px;
        }
        .clock-numbers ul .number {
            line-height: 26px;
        }
        .pin {
            left: 16%;
        }
        .putttime {
            left: 50%;
            top: 36%;
        }
    }

@media only screen and (device-width : 375px) and (device-height : 812px) {

    .putt-pin-green h3 {
        font-size: 18px;
        padding: 16px 0px 8px 2px;
    }        
    .btn-circle {
        padding: 10px;
    }
    .clock-numbers ul .number {
        /* line-height: 28px; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
            
}
/*=================  for safari only ===============*/
/* @media not all and (min-resolution:.001dpcm) { @media {

    .btn-circle {
        padding:5px;
        margin-right: 3px;
    }
    .putt-pin-green h3 {
        font-size: 15px;
        padding:4px 0 0;
    }
    .footer .bottom-footer ul li{
        padding: 4px 0px;
    }
    .plus-minus-angle {
        bottom: -28px;
    }
}} */
@media screen and (-webkit-min-device-pixel-ratio:0) {
 
    .btn-circle {
        padding: 5px 3px;
        margin-right: 3px;
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 27px;
        font-weight: 700;
    }
    .putt-pin-green h3 {
        font-size: 14px;
        padding: 8px 0 5px;
    }
    .footer .bottom-footer ul li{
        padding: 4px 0px;
    }
    .plus-minus-angle {
        bottom: -28px;
    }
   }

   div.main-container > div > div > div.vSliderBox > div.vSlider{
       /* opacity: 0.7; */
   }

   @media screen 
  and (device-width: 768px) 
  and (device-height: 1024px) {
    div.main-container > div > div > div.vSliderBox > div.vSlider {
        bottom: 171px;
        /* opacity: 0.7; */
    }
    .vSliderBox .vSlider.vPLength {
        right: 112px;
    }
    .vSliderBox .vSlider.vPASlope {
        left: 203px;
    }
    .graph-container .container {
        width: 100%;
    }
    .D-footer {
        display: none;
    }
  }

  

   @media (min-width:1024px){
    body{padding-bottom: 44px;position: relative;}
    .read-putt{padding: 30px 15px;box-shadow: 0 2px 10px rgba(0,0,0,0.15);display: inline-block;width: 100%;}
    .read-putt form{width: 50%;margin: auto;}
   
    .graph-container {
        /* width: 680px; */
        margin: 0 auto;
        position: relative;
        overflow-x: hidden;
}
    .vSliderBox .vSlider {
        position: absolute;
    }
    .slider-text-container sup {
        font-size: 12px;
        top: -8px;
    }
    .vSliderBox .vSlider.vGreenSpeed {
        left: 55px;
    }
    .vSliderBox .vSlider.vPASlope {
        left: 147px;
    }
    .vSliderBox .vSlider.vPLength {
        right: 74px;
    }
    .vSliderBox .vSlider.vPuttTime {
        right: 170px;
    }
    .header-puttpower-text sup {
        top: -9px;
    }
    .putttime h3 span {
        font-size: 13px;
        top: -11px;
        position: relative;
    }
    
    
}

@media screen 
and (device-width: 768px) 
and (device-height: 1024px) { 
    .footer {
        position: fixed;
    }
    .footer .bottom-footer ul li {
        line-height: 28px;
        padding: 10px 0px;
        font-size: 34px;
        font-family: 'Barlow Condensed', sans-serif;
    }
    .right_center {
        font-size: 32px;
    }
    .clock {
        position: relative;
        margin-top: 25px;
        margin-right: 5px;
    }
    .menu-btn sup {
        font-size: 15px;
        margin-top: 0;
        display: inline-block;
        position: relative;
        top: -12px;
        left: -1px;
    }
    #menu .menu-btn a h3 {
        /* font-size: 20px; */
        font-family: 'Barlow Condensed', sans-serif;
        display: block;
        height: 24px;
        margin-bottom: 10px;
    }
    .footer .bottom-footer ul li .fa-cog, .footer .bottom-footer ul li .fa-home {
        font-size: 31px;
    }
    .vSlider .slider.slider-vertical {
        height: 283px;
    }
    .slider.slider-vertical .slider-track {
        width: 56px;
        top: -1px;
    }
    .slider.slider-vertical .slider-track-low, .slider.slider-vertical .slider-track-high {
        width: 56px;
    }
    .vPLength .slider-handle.min-slider-handle.round {
        height: 25px;
        width: 64px;
    }
    .vPuttTime .slider-handle.min-slider-handle.round {
        height: 25px;
        width: 64px;
    }
    .vPASlope .slider-handle.min-slider-handle.round {
        height: 25px;
        width: 64px;
    }
    .vGreenSpeed .slider-handle.min-slider-handle.round {
        height: 25px;
        width: 64px;
    }
    span.slider-text-container {
        font-size: 44px;
    }
    .vPuttTime > .slider-text-container {
        bottom: 60px;
        left: -46px;
    }
    .vPLength > .slider-text-container {
        bottom: 75px;
        left: -60px;
    }
    .vPASlope > .slider-text-container {
        bottom: 91px;
        left: -79px;
    }
    .vGreenSpeed > .slider-text-container {
        bottom: 74px;
        left: -63px;
    }
    .vPLength span.slider-value-container {
        left: -4px;
    }
    .vPuttTime span.slider-value-container {
        left: -19px;
    }
    .vPASlope span.slider-value-container {
        left: -16px;
    }
    .vGreenSpeed span.slider-value-container {
        left: -6px;
    }
    span.slider-plus-btn {
        font-size: 36px;
        width: 70px;
        height: 70px;
        top: -180px;
        left: -4px;
        padding-top:11px;
    }
    span.slider-minus-btn {
        top: -95px;
        font-size: 36px;
        left: -3px;
        width: 70px;
        height: 70px;
        padding-top: 12px;
    }
    .vSlider .slider.slider-vertical:after {
        width: 57px;
    }
    span.slider-value-container {
        position: absolute;
        bottom: -62px;
    }
    
.puttpower-value {
    padding-right: 17px;
}
.vSliderBox .vSlider.vGreenSpeed {
    left: 70px;
}
.vSliderBox .vSlider.vPASlope {
    left: 159px;
}
.putttime h3 span {
    vertical-align: text-top;
    top: 4px;
    font-size: 9px;
    position: relative;
}
}

@media screen 
and (device-width: 1024px) 
and (device-height: 1366px) {
  div.main-container > div > div > div.vSliderBox > div.vSlider {
      bottom: 171px;
  }
  .vSliderBox .vSlider.vPLength {
      right: 112px;
  }
  .vSliderBox .vSlider.vPASlope {
      left: 203px;
  }
  .D-footer {
      display: none;
  }
  body {
      padding-bottom: 0px;
      position: relative;
  }
  .vSliderBox .vSlider.vPuttTime {
    right: 199px;
}
.vSliderBox .vSlider.vPLength {
    right: 108px;
}
.vSliderBox .vSlider.vGreenSpeed {
    left: 95px;
}
.vSliderBox .vSlider.vPASlope {
    left: 189px;
}
.footer {
    position: fixed;
}
.footer .bottom-footer ul li i {
    display: block;
    margin-bottom: 9px;
    font-size: 28px;
}
.footer .bottom-footer ul li h3 {
    height: 24px;
}
.footer .bottom-footer ul li .fa-cog, .footer .bottom-footer ul li .fa-home {
    font-size: 30px;
}
.footer .bottom-footer ul li {
    line-height: 28px;
    padding: 10px 0px;
    font-size: 34px;
    font-family: 'Barlow Condensed', sans-serif;
}
.right_center {
    font-size: 32px;
}
.clock {
    position: relative;
    margin-top: 25px;
    margin-right: 5px;
}
.set-angles img {
    transform-origin: 93px 11px;
}
.vSlider .slider.slider-vertical {
    height: 283px;
}
.slider.slider-vertical .slider-track {
    width: 56px;
    top: -1px;
}
.slider.slider-vertical .slider-track-low, .slider.slider-vertical .slider-track-high {
    width: 56px;
}
.vPLength .slider-handle.min-slider-handle.round {
    height: 25px;
    width: 64px;
}
.vPuttTime .slider-handle.min-slider-handle.round {
    height: 25px;
    width: 64px;
}
.vPASlope .slider-handle.min-slider-handle.round {
    height: 25px;
    width: 64px;
}
.vGreenSpeed .slider-handle.min-slider-handle.round {
    height: 25px;
    width: 64px;
}
span.slider-text-container {
    font-size: 44px;
}
.vPuttTime > .slider-text-container {
    bottom: 60px;
    left: -46px;
}
.vPLength > .slider-text-container {
    bottom: 75px;
    left: -60px;
}
.vPASlope > .slider-text-container {
    bottom: 91px;
    left: -79px;
}
.vGreenSpeed > .slider-text-container {
    bottom: 74px;
    left: -63px;
}
.vPLength span.slider-value-container {
    left: -4px;
}
.vPuttTime span.slider-value-container {
    left: -19px;
}
.vPASlope span.slider-value-container {
    left: -16px;
}
.vGreenSpeed span.slider-value-container {
    left: -6px;
}
span.slider-plus-btn {
    font-size: 36px;
    width: 70px;
    height: 70px;
    top: -180px;
    left: -4px;
    padding-top: 12px;
}
span.slider-minus-btn {
    top: -95px;
    font-size: 36px;
    left: -3px;
    width: 70px;
    height: 70px;
    padding-top: 12px;
}

span.slider-value-container {
    position: absolute;
    bottom: -62px;
}
.puttpower-value {
    padding-right: 17px;
}

.vSliderBox .vSlider.vGreenSpeed {
    left: 70px;
}
.vSliderBox .vSlider.vPASlope {
    left: 159px;
}
.header-labels-flex sup {
    top: -10px;
}
.putttime h3 span {
    font-size: 13px;
    top: 4px;
    position: relative;
}
.putttime {
    position: absolute;
    left: 50%;
    top: 42%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
}
.putttime-top {
    top: 22%;
}
.clock-watch .clock-numbers ul .number {
    line-height: 34px;
}
.clock-numbers ul .number {
    font-size: 38px;
}
}

@media screen and (min-width: 1024px) {
    .graph-container .container {
        width: 1170px;
    }
    .puttpower-value {
        padding-right: 15px;
    }
    .header-puttpower-text sup {
        font-size: 15px;
    }
}
@media screen and (min-width: 1600px) {
    
    .graph-container .container {
        width: 1865px;
    }
}


/* Written by Vishal */
.G-btn > a {
    color: white;
}
.G-btn{
    height: 40px;
    width: 150px;
}
.rotateImage{
    width:150px;
    height:150px;
    position: absolute;
    top: 60%;
    left: 50%;
     margin: -15% 0 0 -9%;
    /* position: absolute;
    top:26%;
    left:40%; */
}
.rotateImagecontainer{
    display:flex;
    justify-content: center;
    height:100vh;
    
}
sup {
    font-size: 9px;
}
  .slider-selection{
        background: #fff !important;
    }
/* @media screen and (min-width: 320px) and (max-width: 967px) and (orientation: landscape) {
  
  .grassbg{
      height: 100vw;
  }
  .grassbg canvas {
        height: 928px;
        width:295px;
  }
} */