.loginFormContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 88%; */
    height: calc(100vh - 150px);
    background: #f4f4f4;
}

.loginBox{
    min-width: 500px;
    padding: 30px;
    background: #fff;
    box-shadow: 0 10px 50px #ddd;
}
.loginBox .form-group label{
    margin-bottom: 10px;
    font-size: 14px;
}
.loginBox .form-group .form-control{
    border-radius: 0;
    background: #fff;
    box-shadow: none;
    height: auto;
    padding: 10px 15px;
}
input:-internal-autofill-selected{
    background-color: #fff !important;
}